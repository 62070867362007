<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    min-width="auto"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormat"
        :label="label ? 'Periodo' : ''"
        :clearable="clearable"
        hide-details
        outlined
        readonly
        filled
        dense
      >
        <template v-slot:append-outer>
          <v-icon
            v-bind="attrs"
            v-on="on"
            tabindex="1"
            :color="disabled ? '' : 'info'"
            :title="disabled ? '' : 'Seleccionar periodo'"
            :disabled="disabled"
          >
            fas fa-calendar-day
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      color="primary"
      show-current
      scrollable
      no-title
      :min="min"
      @input="eventoSeleccion"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/**
 *  Componente para seleccionar periodos, devuelve la fecha en formato MM/YYYY
 * 
 */
export default {
  data () {
    return {
      date: null,
      menu: false,
    }
  },
  props: {
    value: String,
    label: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
    }
  },
  computed: {
    dateFormat: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    date () {
      this.dateFormat = this.formatDate(this.date)
      this.$emit('change')
    },
    dateFormat (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.date = null
      }
    }
  },
  methods: {
    eventoSeleccion (periodo) {
      this.menu = false
      // devuelve el periodo cuando hace click en un mes del modal
      this.$emit('selecciono', this.formatDate(periodo))
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      return `${month}/${year}`
    }
  }
}
</script>