<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-scale-transition>
            <v-btn
              v-if="liquidacionesFormateadas.length != 0"
              style="position: absolute; top: -15px; right: 165px; z-index: 1"
              color="#1D6B40"
              title="Descargar reporte liquidaciones"
              small
              dark
              fab
              @click="descargarLiquidacion()"
            >
              <v-icon>fas fa-file-excel</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-scale-transition origin="center center">
            <v-btn
              v-if="liquidaciones.length > 0"
              :disabled="!periodoValido"
              style="position: absolute; top: -15px; right: 110px; z-index: 1"
              color="error"
              title="Eliminar liquidación"
              small
              dark
              fab
              @click="eliminarLiquidacion()"
            >
              <v-icon>fas fa-trash</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-scale-transition origin="center center">
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              title="Generar liquidación"
              small
              fab
              @click="modalNuevaLiquidacion = true"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-expansion-panel-header
            class="py-0 px-4 text-h5"
            style="cursor: default"
          >
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                  md="2"
                  class="py-1"
                  v-if="!filtro.rango"
                >
                  Periodo
                  <PeriodoPicker v-model="filtro.periodo" />
                </v-col>
                <v-col cols="6" md="2" v-if="filtro.rango" class="py-1">
                  <div style="display: flex; flex-direction: column">
                    Fecha desde
                    <FechaPicker
                      v-model="filtro.desde"
                      hide-details
                      clearable
                    />
                  </div>
                </v-col>
                <v-col cols="6" md="2" v-if="filtro.rango" class="py-1">
                  <div style="display: flex; flex-direction: column">
                    Fecha hasta
                    <FechaPicker
                      v-model="filtro.hasta"
                      hide-details
                      clearable
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="1" class="py-1">
                  <v-checkbox
                    label="Rango"
                    v-model="filtro.rango"
                    class="pt-3"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="3" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    deletable-chips
                    small-chips
                    clearable
                    multiple
                    @change="setMarcas()"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3" class="py-1">
                  Supervisor
                  <v-autocomplete
                    v-model="filtro.supervisor"
                    item-value="id"
                    item-text="nombre"
                    :items="
                      supervisores.filter(
                        sup =>
                          filtro.familia.includes(sup.familia_id) ||
                          sup.familia_id === null
                      )
                    "
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.vendedor = null"
                    :readonly="switchTabla"
                    :disabled="switchTabla"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Vendedor
                  <v-autocomplete
                    v-model="filtro.vendedor"
                    item-value="Vendedor_Id"
                    item-text="Nombre"
                    :items="
                      vendedores.filter(
                        vend => vend.Supervisor_id === filtro.supervisor
                      )
                    "
                    hide-details
                    clearable
                    outlined
                    dense
                    :readonly="switchTabla"
                    :disabled="switchTabla"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" xl="2" class="py-1">
                  Concepto
                  <v-autocomplete
                    v-model="filtro.concepto"
                    item-value="Id"
                    item-text="Nombre"
                    :items="conceptos"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Titular
                  <v-text-field
                    v-model.trim="filtro.titular"
                    hide-details
                    outlined
                    dense
                    :readonly="switchTabla"
                    :disabled="switchTabla"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Modelo
                  <v-text-field
                    v-model.trim="filtro.modelo"
                    hide-details
                    outlined
                    dense
                    :readonly="switchTabla"
                    :disabled="switchTabla"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="2" xl="1" class="py-1">
                  Preventa
                  <v-text-field
                    v-model.trim="filtro.preventa"
                    type="text"
                    hide-details
                    outlined
                    dense
                    :readonly="switchTabla"
                    :disabled="switchTabla"
                  ></v-text-field>
                </v-col>
                <BtnFiltro :loading="load" @clear="limpiar()" />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 my-5"
        :items="liquidacionesFormateadas"
        :headers="headers"
        :loading="load"
        :search="search"
        :expanded.sync="expanded"
        :single-expand="singleExpand"
        :item-class="itemRowBackground"
        :items-per-page="-1"
        hide-default-footer
        :show-expand="mostrarExpand"
        item-key="id"
        dense
      >
        <template v-slot:top>
          <v-row
            class="py-4"
            style="display: flex; justify-content: space-between"
          >
            <v-col cols="6" sm="6" md="3">
              <v-switch
                v-model="switchTabla"
                class="mt-n2 ml-4"
                label="Agrupar"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="6" sm="3" class="mr-4">
              <SearchDataTable class="mt-n2" v-model="search" />
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </template>

        <template v-slot:[`item.Modelo`]="{ item }">
          <span
            style="text-overflow: ellipsis ellipsis; white-space: nowrap"
            :style="
              item.Modelo_descripcion && item.Modelo_descripcion.length > 30
                ? 'font-size: 10px;'
                : 'font-size: 14px;'
            "
            v-if="item.Modelo_descripcion"
          >
            {{ item.Modelo_descripcion }}
          </span>
          <span v-else>
            {{ item.Modelo }}
          </span>
        </template>

        <template v-slot:[`item.Sucursal`]="{ item }">
          <span
            style="text-overflow: ellipsis ellipsis; white-space: nowrap"
            :style="
              item.Sucursal && item.Sucursal.length > 20
                ? 'font-size: 10px;'
                : 'font-size: 14px;'
            "
          >
            {{ item.Sucursal }}
          </span>
        </template>
        <template v-slot:[`item.Total`]="{ item }">
          {{
            sumarTotal(
              item.Liquidaciones?.filter(
                l => (l.Vendedor_id = item.Vendedor_id)
              )
            )
          }}
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.Importe | formatMoney }}
        </template>

        <!-- sub tabla del detalle -->

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              class="cebra my-2"
              sort-by="concepto"
              :items="item.Liquidaciones"
              :headers="headers_detalle"
              :items-per-page="-1"
              :item-class="itemRowBackgroundDetalle"
              hide-default-footer
              dense
              @contextmenu:row="click_derecho"
            >
              <template v-slot:[`item.Importe`]="{ item }">
                {{ item.Importe | formatMoney }}
              </template>
              <template v-slot:[`item.Modelo`]="{ item }">
                <span
                  style="text-overflow: ellipsis ellipsis; white-space: nowrap"
                  :style="
                    (item.Modelo && item.Modelo.length > 25) ||
                    (item.Modelo_descripcion &&
                      item.Modelo_descripcion.length > 25)
                      ? 'font-size: 10px;'
                      : 'font-size: 14px;'
                  "
                >
                  {{ item.Modelo || item.Modelo_descripcion }}
                </span>
              </template>
              <template v-slot:no-data>
                <div @contextmenu="click_derecho">No hay datos disponibles</div>
              </template>
            </v-data-table>
          </td>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <ModalNuevaLiquidacion v-model="modalNuevaLiquidacion" />
    <ModalAgregar
      @buscar="handleBuscar($event)"
      v-model="modalAgregar"
      :item="selectedItem"
      :conceptos="conceptos"
      :periodo="filtro.periodo"
    />
    <ModalEliminar
      @buscar="handleBuscar($event)"
      v-model="modalEliminar"
      :periodo="filtro.periodo"
      :liquidaciones_id="liquidaciones_seleccionadas"
    />

    <ModalEditar
      @buscar="handleBuscar($event)"
      v-model="modalEditar"
      :periodo="filtro.periodo"
      :item="selectedItem"
      :liquidaciones_id="liquidaciones_seleccionadas"
    />
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="modalAgregar = true">
          <v-list-item-title>
            <v-icon color="success" small left>fas fa-plus</v-icon>
            Agregar
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="editar" v-if="mostrarEliminar">
          <v-list-item-title>
            <v-icon color="yellow" small left>fas fa-edit</v-icon>
            Editar
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="eliminar" v-if="mostrarEliminar">
          <v-list-item-title>
            <v-icon color="error" small left>fas fa-trash</v-icon>
            Eliminar
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>

<script>
import PeriodoPicker from '../../components/util/PeriodoPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import { mapGetters, mapState } from 'vuex'
import { format_money, format_money_round } from '../../util/utils'
import ModalNuevaLiquidacion from '../../components/comisiones-salon/comisiones/ModalNuevaLiquidacion.vue'
import moment from 'moment'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalAgregar from '../../components/comisiones-salon/comisiones/ModalAgregar.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import ModalEliminar from '../../components/comisiones-salon/comisiones/ModalEliminar.vue'
import { utils, write } from 'xlsx'
import FileSaver from 'file-saver'
import ModalEditar from '../../components/comisiones-salon/comisiones/ModalEditar.vue'
import FechaPicker from '../../components/util/FechaPicker.vue'

export default {
  data() {
    return {
      x: 0,
      y: 0,
      showMenu: false,
      panel: 0,
      load: false,
      search: '',
      selectedId: null,
      selectedItem: null,
      modalNuevaLiquidacion: false,
      mostrarEliminar: true,
      modalEditar: false,
      headers: [],
      periodoValido: false,
      headersDesagrupados: [
        { text: 'Empresa', value: 'nombreEmpresa' },
        { text: 'Vendedor', value: 'nombreVendedor' },
        { text: 'Supervisor', value: 'nombreSupervisor' },
        { text: 'Sucursal', value: 'Sucursal' },

        { text: 'Concepto', value: 'conceptoNombre' },
        { text: 'Preventa', value: 'Preventa' },
        { text: 'Modelo', value: 'Modelo' },
        { text: 'Patente', value: 'Patente' },
        {
          text: 'Importe',
          value: 'Importe',
          align: 'right'
        }
      ],
      headersAgrupados: [
        { text: 'Vendedor', value: 'Vendedor' },
        { text: 'Empresa vendedor', value: 'nombreEmpresaOrigen' },
        { text: 'Supervisor', value: 'Supervisor' },
        {
          text: 'Total',
          value: 'Total',
          align: 'right'
        }
      ],

      headers_detalle: [
        { text: 'Empresa PV', value: 'nombreEmpresa' },
        // { text: 'Sucursal', value: 'Sucursal' },
        { text: 'Titular', value: 'Titular' },
        { text: 'Concepto', value: 'conceptoNombre' },
        { text: 'Preventa', value: 'Preventa' },
        { text: 'Modelo', value: 'Modelo' },
        { text: 'Modelo descripcion', value: 'Modelo_descripcion' },
        { text: 'Patente', value: 'Patente' },
        {
          text: 'Importe',
          value: 'Importe',
          align: 'right'
        }
      ],

      cabecera: [],
      expanded: [],
      mostrarExpand: false,
      singleExpand: false,
      conceptos: [],

      switchTabla: false,

      expandir_todos: false,

      liquidaciones: [],
      liquidacionesFormateadas: [],

      liquidaciones_seleccionadas: null,

      filtro: {
        familia: [],
        empresa: null,
        periodo: '',
        supervisor: null,
        vendedor: null,
        concepto: null,
        titular: '',
        modelo: '',
        preventa: '',
        rango: false,
        desde: '',
        hasta: ''
      },

      vendedores: [],

      modalAgregar: false,
      modalEliminar: false
    }
  },
  methods: {
    async buscar() {
      if (
        (this.filtro.periodo.length === 0 && this.filtro.desde.length === 0 && this.filtro.hasta.length === 0) ||
        this.filtro.familia.length == 0 ||
        this.filtro.familia == null
      ) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar un periodo y una empresa.',
          color: 'warning'
        })
      } else {
        this.load = true
        await this.$store
          .dispatch('comisionesVN/buscar', {
            ...this.filtro
          })
          .then(res => {
            if (res.exito) {
              this.liquidaciones = res.data
              const mesActual = moment.utc().format('MM/YYYY')
              let periodo = ''
              let periodoLiquidaciones = null
              if (this.liquidaciones.length > 0) {
                periodoLiquidaciones = this.liquidaciones[0].Periodo || null
                periodo =
                  moment.utc(periodoLiquidaciones).format('MM/YYYY') || null

                this.liquidaciones_seleccionadas = Array.from(
                  new Set(this.liquidaciones.map(liq => liq.Liquidacion_id))
                )

                this.periodoValido = mesActual === periodo || null

                this.liquidaciones.forEach(liquidacion => {
                  liquidacion.Empresa_origen_id
                    ? (liquidacion.nombreEmpresaOrigen = this.familias.find(
                        empresa => empresa.id === liquidacion.Empresa_origen_id
                      )?.nombre)
                    : null

                  liquidacion.Modelo_descripcion
                    ? null
                    : (liquidacion.Modelo_descripcion = '')
                  liquidacion.Modelo ? null : (liquidacion.Modelo = '')

                  liquidacion.conceptoNombre = this.conceptos.find(
                    concepto => (concepto.Id = liquidacion.Concepto_id)
                  )?.Nombre

                  liquidacion.nombreSupervisor =
                    liquidacion.nombreSupervisor.toUpperCase()

                  liquidacion.nombreVendedor = this.vendedores
                    .find(
                      vendedor =>
                        vendedor.Vendedor_Id === liquidacion.Vendedor_d_id
                    )
                    ?.Nombre.toUpperCase()

                  liquidacion.nombreEmpresa = this.familias.find(
                    empresa => empresa.id === liquidacion.Familia_id
                  )?.nombre
                })
                this.liquidaciones = this.liquidaciones.sort((a, b) => {
                  return a.nombreVendedor.localeCompare(b.nombreVendedor)
                })

                this.liquidacionesFormateadas = this.liquidaciones
              }
              this.load = false
            }
          })

        if (!this.switchTabla) {
          this.liquidacionesFormateadas = this.liquidaciones
        } else {
          this.liquidacionesFormateadas = this.agruparPorVendedor(
            this.liquidaciones
          )

          if (this.liquidaciones.length > 0) {
            const liquidaciones_id = Array.from(
              new Set(this.liquidaciones.map(liq => liq.Liquidacion_id))
            )

            await this.$store
              .dispatch('comisionesVN/getVendedoresSinComision', {
                liquidaciones_id: liquidaciones_id
              })
              .then(res => {
                if (res.exito) {
                  let vendedoresSinComision = res.data
                  vendedoresSinComision.forEach(item => {
                    item.nombreEmpresaOrigen = this.familias.find(
                      empresa => empresa.id === item.Empresa_origen_id
                    )?.nombre
                    item.Total = item.Total ? item.Total : 0
                    item.Liquidaciones = []
                    item.id = item.Vendedor_id
                    item.Supervisor = item.Supervisor.toUpperCase()
                    item.Vendedor = item.Vendedor.toUpperCase()
                  })

                  vendedoresSinComision = vendedoresSinComision.filter(item => {
                    return this.filtro.familia.includes(item.Empresa_origen_id)
                  })

                  this.liquidacionesFormateadas = [
                    ...this.liquidacionesFormateadas,
                    ...vendedoresSinComision
                  ]
                } else {
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'error'
                  })
                }
              })
          }
        }

        this.load = false
      }
    },

    handleBuscar(event) {
      if (event) {
        this.buscar()
      }
    },

    agruparPorVendedor(arr) {
      const resultado = {}

      arr?.forEach((item, index) => {
        const vendedor = item.nombreVendedor
        const supervisor = item.nombreSupervisor?.toUpperCase()
        const total = item.Total_x_vendedor
        const Vendedor_id = item.Vendedor_id
        const nombreEmpresaOrigen = item.nombreEmpresaOrigen
        const id = index

        if (!resultado[vendedor]) {
          resultado[vendedor] = {
            Supervisor: supervisor,
            Vendedor: vendedor,
            Total: total,
            Liquidaciones: [],
            Vendedor_id,
            nombreEmpresaOrigen,
            id
          }
        }

        resultado[vendedor].Liquidaciones.push(item)
      })

      return Object.values(resultado)
    },

    limpiar() {
      this.filtro.familia = []
      this.filtro.supervisor = null
      this.filtro.vendedor = null
      this.filtro.concepto = null
      this.filtro.periodo = ''
      this.filtro.titular = ''
      this.filtro.modelo = ''
      this.filtro.preventa = ''

      this.filtro.desde = ''
      this.filtro.hasta = ''
      this.filtro.rango = false
      this.liquidaciones = []
      this.liquidacionesFormateadas = []
    },

    itemRowBackground(item) {
      return this.expanded.find(e => e.id == item.id)
        ? ['background-color: rowsel', 'font-weight-bold']
        : ''
    },

    setMarcas() {
      const marcas = this.empresas.filter(
        emp => emp.familia == this.filtro.familia
      )
      this.filtro.empresa = null
      this.filtro.supervisor = null
      this.filtro.vendedor = null

      if (marcas.length === 1) {
        this.filtro.empresa = marcas[0].id
      }
    },

    async eliminarLiquidacion() {
      // eliminar la liquidacion para el último periodo
      this.modalEliminar = true
    },

    periodoMesAnterior() {
      const mesAnterior = moment().subtract(1, 'months')
      this.filtro.periodo = mesAnterior.format('MM/YYYY')
    },

    itemRowBackgroundDetalle(item) {
      return item.Id == this.selectedId ? ['font-weight-bold row-selected'] : ''
    },

    click_derecho(event, item) {
      event.preventDefault()
      this.showMenu = false
      this.mostrarEliminar = true
      this.x = event.clientX
      this.y = event.clientY
      if (item) {
        this.$nextTick(() => {
          this.showMenu = true
          this.selectedItem = JSON.parse(JSON.stringify(item.item))
          this.selectedId = JSON.parse(JSON.stringify(item.item.Id))
        })
      } else {
        this.$nextTick(() => {
          this.showMenu = true
          this.mostrarEliminar = false
          this.selectedItem = this.expanded[0]
        })
      }
    },

    sumarTotal(array, sin_formato) {
      let total = 0
      array?.forEach(detalle => {
        total += detalle.Importe
      })
      if (sin_formato == 1) return total
      else return format_money_round(total)
    },

    descargarLiquidacion() {
      if (this.liquidaciones.length > 0) {
        const cabecera = [
          [
            'Empresa',
            'Vendedor',
            'Sucursal',
            'Concepto',
            'Preventa',
            'Titular',
            'Patente',
            'Modelo',
            'Modelo Descripcion',
            'Importe',
            'Precio lista',
            'Precio base',
            'Fecha factura',
            'Fecha patentamiento',
            'Fecha entrega',
            'Total'
          ]
        ]

        const ws = utils.aoa_to_sheet(cabecera)
        const array = []

        this.liquidaciones.forEach(liquidacion => {
          let fechaEntrega = ''
          let fechaFactura = ''
          let fechaPatentamiento = ''

          if (liquidacion.FechaFactura) {
            fechaFactura = moment(liquidacion.FechaFactura).format('DD/MM/YYYY')
          }

          if (liquidacion.FechaPatentamiento) {
            fechaPatentamiento = moment(liquidacion.FechaPatentamiento).format(
              'DD/MM/YYYY'
            )
          }

          if (liquidacion.FechaEntrega) {
            fechaEntrega = moment(liquidacion.FechaEntrega).format('DD/MM/YYYY')
          }

          // Format Money
          let importe = null
          let precioLista = null
          let precioBase = null

          if (liquidacion.Importe) {
            importe = format_money(liquidacion.Importe)
          }

          if (liquidacion.PrecioBase) {
            precioBase = format_money(liquidacion.PrecioBase)
          }

          if (liquidacion.PrecioLista) {
            precioLista = format_money(liquidacion.PrecioLista)
          }

          let data = {
            empresa: liquidacion.nombreEmpresa,
            vendedor: liquidacion.nombreVendedor,
            sucursal: liquidacion.Sucursal,
            concepto: liquidacion.conceptoNombre,
            preventa: liquidacion.Preventa,
            titular: liquidacion.Titular,
            patente: liquidacion.Patente,
            modelo: liquidacion.Modelo,
            modeloDescripcion: liquidacion.Modelo_descripcion,
            importe,
            precioLista,
            precioBase,
            fechaFactura,
            fechaPatentamiento,
            fechaEntrega
          }
          array.push(data)
        })

        utils.sheet_add_json(ws, array, {
          header: [
            'empresa',
            'vendedor',
            'sucursal',
            'concepto',
            'preventa',
            'titular',
            'patente',
            'modelo',
            'modeloDescripcion',
            'importe',
            'precioLista',
            'precioBase',
            'fechaFactura',
            'fechaPatentamiento',
            'fechaEntrega'
          ],
          skipHeader: true,
          origin: -1
        })

        const columnas = Object.keys(array[0])
        columnas.forEach(columna => {
          const maxLength = array.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          )
          ws['!cols'] = ws['!cols'] || []
          ws['!cols'].push({ wch: maxLength + 2 })
        })

        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Reporte')
        const wbout = write(wb, { bookType: 'xlsx', type: 'array' })
        let name = 'Liquidacion_' + this.filtro.periodo + '.xlsx'
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          name
        )
      }
    },

    async eliminar() {
      this.$swal
        .fire({
          html: `¿Desea <strong>eliminar</strong> el concepto <strong>${this.selectedItem.conceptoNombre}</strong> de la preventa <strong>${this.selectedItem.Preventa}</strong>?`,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          showCancelButton: true,
          allowEnterKey: false
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.$store.state.loading = true
            await this.$store
              .dispatch('comisionesVN/eliminarDetalle', {
                ...this.selectedItem
              })
              .then(async res => {
                if (res.exito) {
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'success'
                  })
                  await this.buscar()
                } else {
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'error'
                  })
                }
              })
            this.$store.state.loading = false
          }
        })
    },

    async editar() {
      this.modalEditar = true
    }
  },

  async created() {
    this.periodoMesAnterior()
    if (this.una_familia) this.filtro.familia = this.familias[0].id
    if (this.una_empresa) this.filtro.empresa = this.empresas[0].id

    this.$store.state.loading = true
    await this.$store.dispatch('genericos/get_supervisores')
    await this.$store.dispatch('comisionesVN/getVendedoresSalon').then(res => {
      if (res.exito) {
        this.vendedores = res.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    })

    await this.$store.dispatch('comisionesVN/getConceptos').then(res => {
      if (res.exito) {
        this.conceptos = res.data
        if (this.conceptos.length === 1) {
          this.filtro.concepto = this.conceptos[0]
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    })
    
    this.$store.state.loading = false

    this.headers = this.headersDesagrupados
  },

  filters: {
    formatMoney(value) {
      return format_money(value)
    }
  },

  computed: {
    ...mapGetters(['una_empresa', 'una_familia']),
    ...mapState(['empresas', 'familias']),
    ...mapState('genericos', ['supervisores'])
  },

  components: {
    PeriodoPicker,
    BtnFiltro,
    ModalNuevaLiquidacion,
    SearchDataTable,
    ModalAgregar,
    BtnConfirmar,
    ModalEliminar,
    ModalEditar,
    FechaPicker
  },

  watch: {
    'filtro.periodo'(val) {
      if (val === null) {
        this.filtro.periodo = ''
      }
    },

    'filtro.desde'(val) {
      if (val === null) {
        this.filtro.desde = ''
      }
    },

    'filtro.hasta'(val) {
      if (val === null) {
        this.filtro.hasta = ''
      }
    },

    async switchTabla(val) {
      if (val) {
        this.filtro.supervisor = null
        this.filtro.vendedor = null
        await this.buscar()

        this.liquidacionesFormateadas = this.agruparPorVendedor(
          this.liquidaciones
        )
        if (this.liquidaciones.length > 0) {
          const liquidaciones_id = Array.from(
            new Set(this.liquidaciones.map(liq => liq.Liquidacion_id))
          )
          this.load = true
          await this.$store
            .dispatch('comisionesVN/getVendedoresSinComision', {
              liquidaciones_id: liquidaciones_id
            })
            .then(res => {
              if (res.exito) {
                let vendedoresSinComision = res.data
                vendedoresSinComision.forEach(item => {
                  item.nombreEmpresaOrigen = this.familias.find(
                    empresa => empresa.id === item.Empresa_origen_id
                  )?.nombre
                  item.Total = item.Total ? item.Total : 0
                  item.Liquidaciones = []
                  item.id = item.Vendedor_id
                  item.Supervisor = item.Supervisor.toUpperCase()
                  item.Vendedor = item.Vendedor.toUpperCase()
                })

                vendedoresSinComision = vendedoresSinComision.filter(item => {
                  return this.filtro.familia.includes(item.Empresa_origen_id)
                })

                this.liquidacionesFormateadas = [
                  ...this.liquidacionesFormateadas,
                  ...vendedoresSinComision
                ]
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
          this.load = false
        }

        this.mostrarExpand = true
        this.headers = this.headersAgrupados
      } else {
        this.mostrarExpand = false
        this.liquidacionesFormateadas = this.liquidaciones
        this.headers = this.headersDesagrupados
      }
    },

    'filtro.rango'(val){
      this.filtro.periodo = ''
      this.filtro.desde = ''
      this.filtro.hasta = ''
      
    }
  }
}
</script>
